import React from 'react';
import { Link } from 'gatsby';
import logo from '../assets/img/logos/logo.png';

const Footer = () => {
  return (
    <footer className="js-footer-is-fixed">
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-3 col-xs-12">
              <div className="footer-logo-wrapper">
                <Link to="/" className="logo-image">
                  <img src={logo} alt="logo" />
                </Link>
                <p className="slogan">
                  Путь.
                  <br />
                  Истина.
                  <br />
                  Жизнь.
                </p>
              </div>
            </div>
            <div className="col-md-9 col-sm-9 col-xs-12">
              <div className="footer-wrapper">
                <span className="scroll-top js-scroll-top">
                  <i className="fa fa-angle-up" />
                </span>

                <ul className="footer-menu helper right">
                  <li>
                    <Link to="/assembly"> О собрании </Link>
                  </li>
                  <li>
                    <Link to="/books"> Литература </Link>
                  </li>
                  <li>
                    <Link to="/articles"> Статьи </Link>
                  </li>
                  <li>
                    <Link to="/contacts"> Контакты </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
