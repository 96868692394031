import React, { useState } from 'react';
import { Link } from 'gatsby';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import logo from '../assets/img/logos/logo.png';

const HeaderMenu = ({ over = true, large = true }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1200px)' });
  const [isMobileMenuVisible, setMobileMenuVisibility] = useState(false);

  return (
    <div
      className={classNames('header', { 'header-over': over, large: large })}>
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-sm-6 col-xs-5">
            <Link to="/" className="logo-image logo-animated">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className="col-md-9 col-sm-6 col-xs-7">
            <nav className="right helper">
              <div
                className="slicknav_menu"
                style={{ display: isTabletOrMobile ? 'block' : 'none' }}>
                <button
                  aria-haspopup="true"
                  role="button"
                  className="slicknav_btn slicknav_collapsed"
                  onClick={() => setMobileMenuVisibility(!isMobileMenuVisible)}>
                  <span className="slicknav_menutxt" />
                  <span className="slicknav_icon slicknav_no-text">
                    <span className="slicknav_icon-bar" />
                    <span className="slicknav_icon-bar" />
                    <span className="slicknav_icon-bar" />
                  </span>
                </button>
                <ul
                  className="slicknav_nav"
                  aria-hidden={isMobileMenuVisible ? 'false' : 'true'}
                  role="menu"
                  style={{ display: isMobileMenuVisible ? 'block' : 'none' }}>
                  <li>
                    <Link to="/assembly" role="menuitem" tabIndex="-1">
                      О собрании
                    </Link>
                  </li>
                  <li>
                    <Link to="/books" role="menuitem" tabIndex="-1">
                      Литература
                    </Link>
                  </li>
                  <li>
                    <Link to="/articles" role="menuitem" tabIndex="-1">
                      Статьи
                    </Link>
                  </li>
                  <li>
                    <Link to="/contacts" role="menuitem" tabIndex="-1">
                      Контакты
                    </Link>
                  </li>
                </ul>
              </div>
              <ul className="menu" style={{ touchAction: 'pan-y' }}>
                <li>
                  <Link to="/assembly" role="menuitem" tabIndex="-1">
                    О собрании
                  </Link>
                </li>
                <li>
                  <Link to="/books">Литература</Link>
                </li>
                <li>
                  <Link to="/articles">Статьи</Link>
                </li>
                <li>
                  <Link to="/contacts">Контакты</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderMenu;
